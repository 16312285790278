
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { StaticImageData } from 'next/image';
import CarouselSlide from '../OurClientsCarouselSlide';
import classes from './index.module.scss';
import Carousel from '@/components/Carousel';
import { ContentWithLabel } from '@/components/common/LayoutWithLabel';
import CccLogo from '@/images/home/brands/ccc.webp';
import DhlLogo from '@/images/home/brands/dhl.svg';
import TiktokLogo from '@/images/home/brands/tiktok.png';
import IngLogo from '@/images/home/brands/ing.svg';
interface References {
    image: StaticImageData;
    description: string;
    company: string;
}
export const references: References[] = [
    {
        image: IngLogo,
        description: 'Handling one-time passwords for online banking operations in over a dozen markets, marketing messages and SMS notifications.',
        company: 'ING Bank',
    },
    {
        image: CccLogo,
        description: "Marketing and transactional messages for the brand's online shop and brick and mortar shops.",
        company: 'CCC',
    },
    {
        image: DhlLogo,
        description: 'SMS messaging related to parcel delivery and customer satisfaction surveys in 220 markets worldwide.',
        company: 'DHL Express',
    },
    {
        image: TiktokLogo,
        description: 'Automatic one-time verification SMS codes when signing up on the website in 150 countries.',
        company: 'TIK TOK app',
    },
];
const OurClientsSection = () => {
    const { t } = useTranslation('home');
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    return (<div className={classes.layout}>
      <ContentWithLabel label={t('Our customers')}>
        <Carousel currentSlideIndex={currentSlideIndex} hideSlideIndicators buttonsSettings={{ xl: 'bottom' }} pages={references.map((reference, idx) => (<CarouselSlide key={idx} companyLogo={reference.image} company={t(reference.company)} description={t(reference.description)}/>))} onSlideChange={(idx) => {
            setCurrentSlideIndex(idx);
        }} gtm="our-clients"/>
      </ContentWithLabel>
    </div>);
};
export default OurClientsSection;

    async function __Next_Translate__getStaticProps__1910ce3e1e7__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/OurClients/components/OurClientsSection/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce3e1e7__ as getStaticProps }
  