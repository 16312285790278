
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import classes from './index.module.scss';
import MediaAboutUsSection from './components/MediaAboutUsSection';
import OurClientsSection from './components/OurClientsSection';
import { Section } from '@/components/common/Section';
import SectionBody from '@/components/common/SectionBody';
const OurClients: React.FunctionComponent = () => (<Section backgroundColor={'white'} disableBorderBottom disableContentPaddingHorizontal>
    <SectionBody main={<div className={classes.layout}>
          <OurClientsSection />
          <MediaAboutUsSection />
        </div>}/>
  </Section>);
export default OurClients;

    async function __Next_Translate__getStaticProps__1910ce3dbe0__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/OurClients/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce3dbe0__ as getStaticProps }
  